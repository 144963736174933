<template>
  <div v-if="this.query.model === '0'" class="logoPanel">
    <img src="../../assets/img/je_logo.png" alt="JE Education" />
  </div>
  <div class="paymentResultContainer">
    <div
      class="succeedPanel"
      v-if="this.updateResult && this.urlType === 'return_url'"
    >
      <el-icon color="green" size="120px"><Select /></el-icon><br />
      Payment successfully,
      {{
        this.query.model === "1"
          ? "the window will close in " +
            this.counter +
            " seconds, or you can manually close it."
          : "you can manually close the tab/window."
      }}
    </div>
    <div
      class="cancelPanel"
      v-if="this.updateResult && this.urlType === 'cancel_url'"
    >
      <el-icon color="red" size="120"><CloseBold /></el-icon><br />
      Cancel successfully,
      {{
        this.query.model === "1"
          ? "the window will close in " +
            this.counter +
            " seconds, or you can manually close it."
          : "you can manually close the tab/window."
      }}
    </div>
    <div
      class="cancelPanel"
      v-if="this.updateResult && this.urlType === 'pending_url'"
    >
      Your payment is pending,
      {{
        this.query.model === "1"
          ? "the window will close in " +
            this.counter +
            " seconds, or you can manually close it."
          : "you can manually close the tab/window."
      }}
    </div>
    <div class="failedPanel" v-if="!this.updateResult">
      <el-icon color="red" size="120"><CloseBold /></el-icon><br />
      {{ this.urlType === "return_url" ? "Payment" : "Cancel Payment" }} failed
      with {{ this.errorMessage }},
      {{
        this.query.model === "1"
          ? "the window will close in " +
            this.counter +
            " seconds, or you can manually close it."
          : "you can manually close the tab/window."
      }}
    </div>
  </div>
</template>
<script>
import { postData } from "../../service/api/index";

export default {
  props: {},
  data() {
    return {
      controllerUrl: "paymate",
      query: {
        transactionId: null,
        studentId: null,
        platform: null,
        model: "0",
      },
      updateResult: false,
      urlType: "return_url",
      errorMessage: "",
      counter: 5,
    };
  },
  created() {
    this.query.transactionId = this.$route.query.r;
    this.query.studentId = this.$route.query.s;
    this.query.platform = this.$route.query.p;
    this.query.model = this.$route.query.m;
    this.urlType = this.$route.query.u;
    let _this = this;
    postData(`${this.controllerUrl}/checkStatus`, this.query).then((res) => {
      let result = res.result;
      if (result && res.code === "200") {
        this.updateResult = true;
        if (result === 2048) {
          this.urlType = "return_url";
        } else if (result === 2 || result === 1) {
          this.urlType = "cancel_url";
        } else if (result === 4) {
          this.urlType = "pending_url";
        }
      } else {
        this.errorMessage = res.message;
        this.updateResult = false;
      }
    });

    if (this.query.model === "1") {
      let timeId = window.setInterval(function() {
        _this.counter -= 1;
        if (_this.counter <= 0) {
          _this.$emitter.emit("TOP_UP_POINTS_PAYMENT_DIALOG_EVENT", "Close");
          window.top.postMessage(
            {
              type: "TOP_UP_POINTS_PAYMENT_DIALOG_EVENT",
              message: "Close",
            },
            "*"
          );

          window.clearInterval(timeId);
          console.log("counter", _this.counter, timeId, window.top);
        }
      }, 1000);
    }
  },
  methods: {},
};
</script>

<style scoped>
.logoPanel {
  margin: 20px auto;
  padding-bottom: 20px;
  width: 200px;
  overflow: hidden;
  text-align: center;
  border-bottom: solid 2px #ccc;
}
.paymentResultContainer {
  margin: 20px auto;
  width: 80%;
  overflow: hidden;
  text-align: center;
}
.paymentResultContainer .succeedPanel,
.paymentResultContainer .cancelPanel,
.paymentResultContainer .failedPanel {
  text-align: center;
  font-size: 2.67rem;
  font-weight: 500;
}
</style>
